<template>
    <div class="container">
        <el-row class="listHeader">
            <el-col class="icon">
                <div>
                    <i class="el-icon-trophy" style="font-size:32px"></i>
                </div>
                <div>报名</div>
            </el-col>
            <el-col>
                <div>
                    <el-button type="primary" size="medium" v-href="'/Enroll/EnrollDetail'"><i class="el-icon-plus"></i> 创建报名</el-button>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-form>
                <el-form-item label="报名状态筛选：">
                    <el-button v-for="(item,index) in statusList" :key="index"
                        size="small"
                        :plain="item.id == status ? false : true"
                        type="primary"
                        @click="status = item.id"
                    >{{item.text}}</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row style="height:calc(100% - 180px)">
            <el-table
                v-loading="loading"
                element-loading-text="数据加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                :data="list"
                border
                style="width: 100%"
                height="100%"
                size="mini"
            >
                <el-table-column
                    prop="LeagueName"
                    label="赛事名称">
                </el-table-column>
                <el-table-column
                    label="报名时间">
                    <template slot-scope="scope">
                        <span >
                            {{scope.row.EnrollBeginTime | dateFormat('yyyy-MM-dd')}}
                            ~
                            <template v-if="scope.row.EnrollEndTime">
                            {{scope.row.EnrollEndTime | dateFormat('yyyy-MM-dd')}}
                            </template>
                            <template v-else>无限期</template>
                        </span>
                        <el-button 
                            type="text"
                            style="margin-left:5px"
                            v-if="[1,2,9].indexOf(scope.row.EnrollStatus) > -1"
                            @click="setUpdateTime(scope.row)"
                        >修改</el-button>
                    </template>
                </el-table-column>
                <el-table-column
                    label="报名方式"
                    width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.EnrollWay == 1">仅代表队</span>
                        <span v-else-if="scope.row.EnrollWay == 2">仅个人</span>
                        <span v-else-if="scope.row.EnrollWay == 3">代表队及个人</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="报名状态"
                    width="100">
                    <template slot-scope="scope">
                        <span>{{[
                            '报名中',
                            '报名结束',
                            '公示中',
                            '公示结束',
                            '修改中',
                            '修改结束',
                            '已完成',
                            '未发布',
                            '未开始',
                            '已取消'
                        ][scope.row.EnrollStatus - 1]}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="info"
                            v-if="[8,10].indexOf(scope.row.EnrollStatus) > -1"
                            v-href="`/Enroll/EnrollDetail/${scope.row.LeagueEnrollId}/${scope.row.LeagueId}/1`"
                        >编辑</el-button>
                        <el-button 
                            size="mini"
                            type="success"
                            v-if="[8].indexOf(scope.row.EnrollStatus) > -1"
                            @click="publish(scope.row.LeagueEnrollId, scope.row.LeagueId)"
                        >发布</el-button>
                        <el-button 
                            size="mini"
                            type="primary"
                            v-if="[1,2,3,4,5,6,7,9].indexOf(scope.row.EnrollStatus) > -1"
                            v-href="`/Enroll/LookEnrollDetail/${scope.row.LeagueEnrollId}/${scope.row.LeagueId}`"
                        >查看</el-button>
                        <el-button 
                            size="mini"
                            type="warning"
                            v-if="[1,2,3,4,5,6,9].indexOf(scope.row.EnrollStatus) > -1"
                            @click="cancel(scope.row.LeagueEnrollId, scope.row.LeagueId)"
                        >取消报名</el-button>
                        <el-button 
                            size="mini"
                            type="danger"
                            v-if="[10].indexOf(scope.row.EnrollStatus) > -1"
                            @click="remove(scope.row.LeagueEnrollId, scope.row.LeagueId)"
                        >删除报名</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-dialog title="修改报名时间" :visible.sync="updateTime.show">
            <el-form ref="updateTimeForm" :model="updateTime" :rules="rules" label-width="100px">
                <el-form-item label="报名时间:" prop="time" v-if="updateTime.beginTime">
                    <el-date-picker
                        v-model="updateTime.time"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-ddTHH:mm:ss"
                        :picker-options="GameTimeOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="报名时间:" prop="time" v-else>
                    <el-date-picker
                        v-model="updateTime.time[0]"
                        type="datetime"
                        placeholder="开始日期"
                        value-format="yyyy-MM-ddTHH:mm:ss"
                        :picker-options="startGameTimeOptions">
                    </el-date-picker>
                    <span>&ensp;至&ensp;</span>
                    <el-date-picker
                        v-model="updateTime.time[1]"
                        type="datetime"
                        placeholder="结束时间"
                        value-format="yyyy-MM-ddTHH:mm:ss"
                        :picker-options="endGameTimeOptions">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="updateTime.show = false">取 消</el-button>
                <el-button type="primary" @click="updateTimeFn">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    GetMyLeagueEnrollList,
    EditLeagueEnrollTime,
    PublishLeagueEnroll,
    CancelLeagueEnroll,
    DelLeagueEnroll
} from '@/api'
export default {
    data(){
        return {
            isOldLeague:false,
            status:null,
            statusList:[
                {id:null,text:'全部'},
                {id:1,text:'未发布'},
                {id:2,text:'报名中'},
                {id:3,text:'已取消'}
            ],
            list:[],
            loading:false,
            updateTime:{
                show: false,
                leagueId: 0,
                leagueEnrollId: 0,
                beginTime:"",
                time:[]
            },
            rules:{
                time:[
                    {required:true,validator:(rule, value, callback)=>{
                        if(!value || value.length == 0){
                            callback(new Error('请选择报名时间'));
                        }else if(!this.updateTime.beginTime && !value[0]){
                            callback(new Error('请选择报名开始时间'));
                        }else{
                            callback();
                        }
                    }}
                ]
            }
        }
    },
    watch:{
        status(){
            this.getList();
        }
    },
    computed:{
        GameTimeOptions(){
            var _this = this;
            return {
                disabledDate(time){
                    if(_this.updateTime.beginTime){
                        return time.getTime() > new Date(_this.updateTime.beginTime).getTime();
                    }else{
                        return true;
                    }
                }
            }
        },
        startGameTimeOptions(){
            var _this = this;
            return {
                disabledDate(time){
                    if(_this.updateTime.time[1]){
                        return time.getTime() > new Date(_this.updateTime.time[1]).getTime();
                    }else{
                        return false;
                    }
                }
            }
        },
        endGameTimeOptions(){
            var _this = this;
            return {
                disabledDate(time){
                    if(_this.updateTime.time[0]){
                        return time.getTime() < new Date(_this.updateTime.time[0]).getTime();
                    }else{
                        return false;
                    }
                }
            }
        }
    },
    created(){
        this.getList();
    },
    methods:{
        getList(){
            this.loading = true;
            GetMyLeagueEnrollList({
                EnrollNowStatus: this.status
            }).then((data)=>{
                this.loading = false;
                if(data.Code == 0){
                    this.list = data.EnrollList;
                }
            }).catch(() => {
                this.loading = false;
            })
        },
        //报名发布
        publish(leagueEnrollId, leagueId){
            this.$confirm('发布之后不可修改赛事报名设置，确认发布？').then(() => {
                let loading = this.$loading({
                    lock: true,
                    text: '正在发布',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                PublishLeagueEnroll({
                    SubLeagueId: leagueId,
                    LeagueEnrollId: leagueEnrollId
                }).then((data) => {
                    loading.close();
                    if(data.Code == 0){
                        this.$message.success('发布成功');
                        this.getList();
                    }
                }).catch(() => {
                    loading.close();
                })
            })
        },
        //报名取消
        cancel(leagueEnrollId, leagueId){
            this.$confirm('确认取消报名？').then(() => {
                let loading = this.$loading({
                    lock: true,
                    text: '正在取消',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                CancelLeagueEnroll({
                    SubLeagueId: leagueId,
                    LeagueEnrollId: leagueEnrollId
                }).then((data) => {
                    loading.close();
                    if(data.Code == 0){
                        this.$message.success('报名取消成功');
                        this.getList();
                    }
                }).catch(() => {
                    loading.close();
                })
            })
        },
        //报名删除
        remove(leagueEnrollId, leagueId){
            this.$confirm('将删除所有的报名数据，确认删除？').then(() => {
                let loading = this.$loading({
                    lock: true,
                    text: '删除中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                DelLeagueEnroll({
                    SubLeagueId: leagueId,
                    LeagueEnrollId: leagueEnrollId
                }).then((data) => {
                    loading.close();
                    if(data.Code == 0){
                        this.$message.success('报名删除成功');
                        this.getList();
                    }
                }).catch(() => {
                    loading.close();
                })
            })
        },
        setUpdateTime(obj){
            var updateTime = this.updateTime;
            updateTime.show = true;
            updateTime.beginTime = obj.GameBeginTime;
            updateTime.leagueId = obj.LeagueId;
            updateTime.leagueEnrollId = obj.LeagueEnrollId;
            updateTime.time = [obj.EnrollBeginTime, obj.EnrollEndTime];
        },
        updateTimeFn(){
            this.$refs['updateTimeForm'].validate((valid)=>{
                if(valid){
                    let loading = this.$loading({
                        lock: true,
                        text: '修改中',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    let updateTime = this.updateTime;
                    EditLeagueEnrollTime({
                        SubLeagueId: updateTime.leagueId,
                        LeagueEnrollId: updateTime.leagueEnrollId,
                        EnrollBeginTime: updateTime.time[0],
                        EnrollEndTime: updateTime.time[1] || '2099-12-31'
                    }).then((data) => {
                        loading.close();
                        if(data.Code == 0){
                            this.$message.success('报名时间修改成功');
                            this.updateTime.show = false;
                            this.getList();
                        }
                    }).catch(() => {
                        loading.close();
                    })
                }
            })
        },
        showAuditReason(text){
            this.$alert(text, {
                title:"审核不通过原因",
                confirmButtonText: '确定',
                closeOnClickModal:true
            });
        }
    }
}
</script>